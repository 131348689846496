export const toggleTab = (tab) => {
  const tabs = document.querySelectorAll('.cmp-tabs__nav-item');
  const contentItems = document.querySelectorAll('.cmp-tabs__content-item');

  // Remove active class from all tabs and content items
  tabs.forEach((t) => {
    t.classList.remove('cmp-tabs__nav-item--active');
    t.setAttribute('aria-selected', 'false');
    t.setAttribute('tabindex', '-1');
  });
  contentItems.forEach(c => c.classList.remove('cmp-tabs__content-item--active'));

  // Add active class to clicked tab and corresponding content item
  const target = tab.dataset.tab;
  tab.classList.add('cmp-tabs__nav-item--active');
  tab.setAttribute('aria-selected', 'true');
  tab.setAttribute('tabindex', '0');
  document.getElementById(target).classList.add('cmp-tabs__content-item--active');
};

// Event listener to handle tab clicks
document.addEventListener('DOMContentLoaded', () => {
  const tabs = document.querySelectorAll('.cmp-tabs__nav-item');
  tabs.forEach((tab) => {
    tab.addEventListener('click', (event) => {
      toggleTab(event.currentTarget);
    });
  });

  // Optionally, handle keyboard navigation
  tabs.forEach((tab) => {
    tab.addEventListener('keydown', (e) => {
      if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
        const currentTab = document.activeElement;
        const direction = e.key === 'ArrowLeft' ? -1 : 1;
        const newIndex = [...tabs].indexOf(currentTab) + direction;

        if (newIndex >= 0 && newIndex < tabs.length) {
          tabs[newIndex].focus();
          toggleTab(tabs[newIndex]);
        }
      }
    });
  });
});
