import Prism from './vendor/prism';

const copyIcon = `
  <svg class="util-margin-right-xs" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" class="icon-sm">
    <path fill="currentColor" fill-rule="evenodd" d="M7 5a3 3 0 0 1 3-3h9a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3h-2v2a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-9a3 3 0 0 1 3-3h2zm2 2h5a3 3 0 0 1 3 3v5h2a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-9a1 1 0 0 0-1 1zM5 9a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1v-9a1 1 0 0 0-1-1z" clip-rule="evenodd"></path>
  </svg>
  Copy Code
`;

const copiedIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" class="icon-sm">
    <path fill="currentColor" fill-rule="evenodd" d="M20.285 6.288a1 1 0 0 1 0 1.414l-9 9a1 1 0 0 1-1.414 0l-3.5-3.5a1 1 0 1 1 1.414-1.414L11 14.586l8.293-8.292a1 1 0 0 1 1.414 0z" clip-rule="evenodd"></path>
  </svg>
  Copied
`;

export const copyCode = (codeElement, button) => {
  const code = codeElement.innerText;
  const buttonElement = button;

  navigator.clipboard.writeText(code).then(() => {
    buttonElement.innerHTML = copiedIcon;

    setTimeout(() => {
      buttonElement.innerHTML = copyIcon; // Reset to the original content
    }, 2000); // Reset the button text after 2 seconds
  }).catch((err) => {
    console.error('Failed to copy text: ', err);
  });
};

// Ensure Prism is loaded and ready
document.addEventListener('DOMContentLoaded', () => {
  Prism.highlightAll();

  // Process all code containers
  const codeContainers = document.querySelectorAll('.cmp-code__container');
  codeContainers.forEach((container) => {
    const codeElement = container.querySelector('code');
    const langNameSpan = container.querySelector('.cmp-code__language-name');
    const copyButton = container.querySelector('button');

    if (codeElement && langNameSpan) {
      const languageClass = Array.from(codeElement.classList).find(cls => cls.startsWith('language-'));
      if (languageClass) {
        const langName = languageClass.replace('language-', '');
        langNameSpan.textContent = langName.charAt(0).toUpperCase() + langName.slice(1);
      }
    }

    if (copyButton) {
      copyButton.addEventListener('click', () => {
        copyCode(codeElement, copyButton);
      });
    }
  });
});
