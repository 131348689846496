import { stickyShrinkObserver } from './sticky-shrink-observer';

require('intersection-observer');

const stickyShrink = () => {
  const watchElement = document.querySelector('.cmp-university-header');
  const stickyElement = document.querySelector('.cmp-header');
  const shrinkClass = 'cmp-header--shrink';

  if ('IntersectionObserver' in window) {
    // We only need this to work if the university-header exists
    if (!watchElement) { return; }

    const watchSticky = new IntersectionObserver((observer) => {
      observer.forEach((entry) => {
        stickyShrinkObserver(entry, stickyElement, shrinkClass);
      });
    });
    watchSticky.observe(watchElement);
  }
};

stickyShrink();
