import { toggleAriaState } from './toggle-aria-state';
import { setIconState } from './set-icon-state';

export const subnavInit = () => {
  const subnavItems = document.querySelectorAll('.js-subnav');
  let toggleState = 'close';

  const closeAll = () => {
    if (!subnavItems.length) { return; }

    [...subnavItems].forEach((toggler) => {
      const togglerID = toggler.getAttribute('id');
      const togglerContent = document.querySelector(`[aria-labelledby="${togglerID}"]`);
      const hiddenIcon = toggler.getAttribute('data-icon-hidden');
      const expandedIcon = toggler.getAttribute('data-icon-expanded');
      toggleState = 'close';

      toggleAriaState(toggleState, toggler, togglerContent);
      setIconState(toggleState, toggler, hiddenIcon, expandedIcon);
    });
  };

  const clickOutside = () => {
    document.addEventListener('click', (event) => {
      if (!event.target.closest('.js-subnav')) {
        closeAll();
      }
    }, false);
  };

  const buildSubnav = () => {
    if (!subnavItems.length) { return; }

    [...subnavItems].forEach((toggler) => {
      const togglerID = toggler.getAttribute('id');
      const togglerContent = document.querySelector(`[aria-labelledby="${togglerID}"]`);

      const hiddenIcon = toggler.getAttribute('data-icon-hidden');
      const expandedIcon = toggler.getAttribute('data-icon-expanded');

      const setToggleState = (state) => {
        setIconState(state, toggler, hiddenIcon, expandedIcon);
        toggleAriaState(state, toggler, togglerContent);
      };

      toggleState = 'close';

      setToggleState(toggleState);

      toggler.addEventListener('click', () => {
        if (toggleState === 'close') {
          closeAll();
          toggleState = 'open';
          setToggleState(toggleState);
        } else {
          toggleState = 'close';
          setToggleState(toggleState);
        }
      });
    });
  };

  buildSubnav();
  clickOutside();
};
